import StatCookie from './StatCookie';
import User from './User';
import StatData from './StatData';
import { LOGIN_EVENT, LOGOUT_EVENT, VIEW_EVENT } from '../model/Events';
import ApiClient from '../client/ApiClient';
import ConfigType from "../model/ConfigType";
import UserType from "../model/UserType";
import {StatCookieType} from "../model/StatCookieType";
import StatDataType from "../model/StatDataType";
import isbot from "isbot";
import { v4 as uuid4 } from 'uuid';
import {resolveInSeconds} from "../utils/date";
import BrowserInteractionTime from "browser-interaction-time";

declare global {
    interface Window { caConfig?: ConfigType|undefined }
}

window.caConfig = window.caConfig || {};

const browserInteractionTime = new BrowserInteractionTime({
    idleTimeoutMs: 15 * 1000,
});

class Stat {
    public readonly id: string
    public params: URLSearchParams
    public account: string|null;
    public platform: string
    public user: UserType
    public cookie: StatCookieType
    public locked: boolean

    constructor() {
        this.params = new URLSearchParams(window.location.search);
        this.account = this.resolveAccount();
        this.platform = this.resolvePlatform();
        this.user = new User();
        this.id = uuid4();
    }

    async start(): Promise<void> {
        if (isbot(navigator.userAgent)) {
            return;
        }

        if (!this.account) {
            return;
        }

        this.init();
        await this.commit();
    }

    init(): void {
        this.cookie = new StatCookie(this.id);
        this.cookie.persist()
        this.locked = false;
        browserInteractionTime.startTimer();

        document.addEventListener(LOGIN_EVENT, this.loginStat.bind(this));
        document.addEventListener(LOGOUT_EVENT, this.logoutStat.bind(this));
        document.addEventListener(VIEW_EVENT, this.commit.bind(this));

        /* istanbul ignore next */
        window.addEventListener('beforeunload', (event) => {
            const target = event.target as any;
            const targetUrl = target.activeElement?.href;
            const currentDomain = window.location.origin;

            if (targetUrl && targetUrl.startsWith(currentDomain)) {
                return;
            }

            this.commitReadingTime().then();
        });
    }

    resolveAccount(): string|null {
        if (window.caConfig?.account) {
            return window.caConfig.account;
        }

        if (this.params.has('account')) {
            return this.params.get('account');
        }

        return null;
    }

    resolvePlatform(): string {
        if (window.caConfig?.platform) {
            return window.caConfig.platform;
        }

        if (this.params.has('platform')) {
            return this.params.get('platform');
        }

        return 'web';
    }

    async commit(): Promise<void> {
        if (!this.lock()) {
            return;
        }

        const apiClient = new ApiClient();

        apiClient.sendStats(this.id, await this.generateStatData());

        this.unlock();
    }

    async generateStatData(): Promise<StatDataType> {
        const statData = new StatData(
            this.account,
            this.platform,
            this.cookie.resolveData().visit,
            this.resolveLogged(),
            this.cookie.getUserId()
        );

        return await statData.serialize();
    }

    resolveLogged(): boolean {
        return this.user.isLogged();
    }

    lock(): boolean {
        if (this.locked) {
            return false;
        }

        this.locked = true;
        return true;
    }

    unlock(): void {
        this.locked = false;
    }

    loginStat(): void {
        this.user.login();
    }

    logoutStat(): void {
        this.user.logout();
    }

    async commitReadingTime(): Promise<void> {
        const apiClient = new ApiClient();

        apiClient.sendReadingTime(this.id, resolveInSeconds(browserInteractionTime.getTimeInMilliseconds()));
    }
}

export default Stat;
